import PropTypes from "prop-types";
import React from "react";
import { navigate, graphql } from "gatsby";
import { isLoggedIn } from "../services/auth";
import Login from "../components/Login";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    const {
      data: {
        site: {
          siteMetadata: { members }
        }
      }
    } = props;

    this.members = members;
    // console.log(title);
  }

  state = {
    username: ``,
    password: ``
  };

  // handleUpdate = event => {
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   });
  // };

  // handleSubmit = event => {
  //   event.preventDefault();
  //   handleLogin(this.state);
  // };

  render() {
    if (isLoggedIn()) {
      if (typeof window !== "undefined") {
        navigate(`/inspireus/`);
      }
    }

    console.log(this.members);

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article theme={theme}>
              <h2>Member-only Content</h2>
              <Login theme={theme} members={this.members} />
            </Article>
          )}
        </ThemeContext.Consumer>
        <style>
          {`
          
        `}
        </style>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default LoginPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query MemberQuery {
    site {
      siteMetadata {
        members
      }
    }
  }
`;
